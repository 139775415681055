/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./FeedbackForm.module.css";
import { feedbackFormItemTypes } from "../../shared/constants/feedbackForm";
import FeedbackFormTextarea from "./Items/FeedbackFormTextarea";
import FeedbackFormFile from "./Items/FeedbackFormFile";
import { submitFeedbackForm } from "../../shared/repos/graphql/feedbackForm";
import FeedbackFormMarkdown from "./Items/FeedbackFormMarkdown";
import Loader from "../Loader/Loader";

class FeedbackForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      feedbackFormItemsValues: [],
      formError: null,
      formItemErrors: [],
      processing: false,
    };
  }

  setFeedbackFormItemValue = (id, value) => {
    const { feedbackFormItemsValues, formItemErrors } = this.state;
    let isExist = false;
    const newFeedbackFormItemsValues = feedbackFormItemsValues.map((item) => {
      if (+item.feedbackFormItemId === +id) {
        isExist = true;
        return {
          ...item,
          values: value,
        };
      }
      return item;
    });
    if (!isExist) {
      newFeedbackFormItemsValues.push({
        feedbackFormItemId: +id,
        values: value,
      });
    }
    const formItemErrorsFiltered = formItemErrors.filter((item) => +item.feedbackFormItemId !== +id);
    this.setState({
      formError: null,
      formItemErrors: formItemErrorsFiltered,
      feedbackFormItemsValues: newFeedbackFormItemsValues,
    });
  }

  submit = async () => {
    const { feedbackFormItemsValues, processing } = this.state;
    const { form, onFormSubmited, orderCode } = this.props;
    const request = {
      feedbackFormId: +form.id,
      feedbackFormItems: feedbackFormItemsValues,
    }
    try {
      if (processing === false) {
        this.setState({
          processing: true,
        });
        const response = await submitFeedbackForm(request, orderCode);
        const result = response.data.submitFeedbackForm;
        if (result.success === true) {
          this.setState({
            formError: null,
            formItemErrors: [],
            processing: false,
          });
          onFormSubmited(result);
        } else {
          this.setState({
            formError: result.message,
            formItemErrors: result.errors,
            processing: false,
          });
        }
      }
    } catch (e) {
      const { graphQLErrors } = e;
      let error = 'Something went wrong. Please try again later.';
      if (graphQLErrors && graphQLErrors.length > 0) {
        error = graphQLErrors[0].message;
      } else if (e?.networkError?.statusCode === 413) {
        error = 'File(s) size is too large';
      }
      this.setState({
        processing: false,
        formError: error,
      });
    }
  }

  cancel = () => {
    const { onCancel } = this.props;
    const { processing } = this.state;
    if (processing === false) {
      onCancel();
    }
  }

  getFormItemError = (feedbackFormItemId) => {
    const { formItemErrors } = this.state;
    const error = formItemErrors.find((item) => +item.feedbackFormItemId === +feedbackFormItemId);
    return error ? error.message : null;
  }

  renderButtons = () => {
    const { form } = this.props;
    const { formError, processing } = this.state;
    return (
      <div className={styles.buttons}>
        { formError ? (<div className={styles.formError}>{ formError }</div>) : null }
        <div className={styles.submitButtonSection}>
          <button
            type="button"
            onClick={ this.submit }
            className={[
              `bg-dark dark:bg-button text-white py-4 flex items-center justify-center`,
              styles.submitButton,
              processing ? styles.processing : ``].join(' ')}>
              { form.submitButtonText || 'Submit' }
          </button>
        </div>
        { form.cancelButtonText
          ? (<div className={[styles.cancelButtonSection, processing ? styles.processing : ''].join(' ')}><span role="button" onClick={this.cancel}>{ form.cancelButtonText }</span></div>)
          : null }
      </div>
    );
  }

  renderFormItem = (item) => {
    let component = null;
    const formItemError = this.getFormItemError(item.id);
    switch (item.type) {
      case feedbackFormItemTypes.TEXTAREA:
        component = <FeedbackFormTextarea errorMessage={formItemError} onInput={(val) => this.setFeedbackFormItemValue(item.id, val)} className={styles.formItem} key={item.id} item={item} />;
        break;
      case feedbackFormItemTypes.FILE:
        component = <FeedbackFormFile errorMessage={formItemError} onInput={(val) => this.setFeedbackFormItemValue(item.id, val)} className={styles.formItem} key={item.id} item={item} />;
        break;
      case feedbackFormItemTypes.MARKDOWN:
        component = <FeedbackFormMarkdown errorMessage={formItemError} onInput={(val) => this.setFeedbackFormItemValue(item.id, val)} className={styles.formItem} key={item.id} item={item} />;
        break;
      default:
        break;
    }
    return component;
  }

  renderFormItems = () => {
    const { form } = this.props;
    return (
      <div className={styles.formContent}>
        <div className={styles.formSubTitleSection}>
          <div className={styles.formSubTitle}>{ form.subTitle }</div>
          <div className={styles.formDescription}>{ form.description }</div>
        </div>
        { form.items.map((item) => this.renderFormItem(item)) }
        { this.renderButtons() }
      </div>
    );
  }

  render() {
    const { form } = this.props;
    const { processing } = this.state;

    return (
      <div className={styles.formContainer}>
        { processing ? (<div className={styles.loaderContainer}><Loader /></div>) : null }
        <div className={styles.formTitle}>{ form.title }</div>
        { this.renderFormItems() }
      </div>
    );
  }
}

FeedbackForm.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    submitButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    cancelButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      isRequire: PropTypes.bool,
      priority: PropTypes.number,
      metadata: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
        markdown: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
      })),
    })),
  }).isRequired,
  onFormSubmited: PropTypes.func,
  onCancel: PropTypes.func,
  orderCode: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};

FeedbackForm.defaultProps = {
    onFormSubmited: () => {},
    onCancel: () => {},
    orderCode: null,
};

export default FeedbackForm;
