/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Items.module.css";
import RedInfoSVG from "../imgs/redInfo.svg";

class FeedbackFormTextarea extends Component {

  constructor(props) {
    super(props);
    this.state = {
      values: [],
      errorMessage: '',
    };
  }

  componentDidMount() {
    const { item, errorMessage } = this.props;
    const values = item.metadata.map((metadata, k) => {
      return {
        ...metadata,
        uniqid: metadata.key || k,
      };
    });
    this.setState({
      values,
      errorMessage,
    });
  }

  componentDidUpdate(prevProps) {
    const { errorMessage } = this.props;
    if (prevProps.errorMessage !== errorMessage) {
      this.setState({
        errorMessage,
      });
    }
  }

  checkRequire = (value) => {
    const { item } = this.props;
    let em = '';
    if (item.isRequire && !value) {
      em = `${item.title} is required`;
    }
    this.setState({
      errorMessage: em,
    });
  }

  saveValue = (key, value) => {
    const { onInput } = this.props;
    const { values } = this.state;
    this.checkRequire(value);
    const newValues = values.map((v) => {
      if (v.uniqid === key) {
        return {
          ...v,
          value,
        };
      }
      return v;
    });
    this.setState({
      values: newValues,
    });
    onInput(newValues.map((v) => {
      return {
        key: v.key,
        value: v.value,
      };
    }));
  }

  renderSingleItem = (value) => {
    return (
      <div key={value.uniqid} className={styles.textItem}>
        { value.label ? (<label>{ value.label }</label>) : null }
        <textarea
          className={[styles.textarea, 'dark:text-dark'].join(' ')}
          onInput={(e) => this.saveValue(value.uniqid, e.target.value)}
          placeholder={value.label || 'Enter message'}
          defaultValue={value.value || ''}
        />
      </div>
    );
  }

  render() {
    const { item, className } = this.props;
    const { values, errorMessage } = this.state;
    return (
      <div className={[styles.formItem, className].join(' ')}>
        <div className={styles.formItemTitle}>{ item.title }</div>
        <div className={styles.formItemSubTitle}>{ item.subTitle }</div>
        { errorMessage ? (<div className={styles.errorMessage}><img src={ RedInfoSVG } alt="i" /> { errorMessage }</div>) : null }
        { values.map((value) => {
          return this.renderSingleItem(value);
        }) }
      </div>
    );
  }
}

FeedbackFormTextarea.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    isRequire: PropTypes.bool,
    priority: PropTypes.number,
    metadata: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
      markdown: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
    })).isRequired,
  }).isRequired,
  onInput: PropTypes.func,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
};

FeedbackFormTextarea.defaultProps = {
    onInput: () => {},
    className: '',
    errorMessage: '',
};

export default FeedbackFormTextarea;
