/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Items.module.css";

class FeedbackFormMarkdown extends Component {

  renderSingleItem = (metadata, index) => {
    return (
      <div key={index} className={styles.markdownItem}>
        { metadata.label ? (<label>{ metadata.label }</label>) : null }
        <div dangerouslySetInnerHTML={{__html: metadata.markdown}}></div>
      </div>
    );
  }

  render() {
    const { item, className } = this.props;
    return (
      <div className={[styles.formItem, className].join(' ')}>
        <div className={styles.formItemTitle}>{ item.title }</div>
        <div className={styles.formItemSubTitle}>{ item.subTitle }</div>
        { item.metadata.map((metadata, k) => {
          return this.renderSingleItem(metadata, k);
        }) }
      </div>
    );
  }
}

FeedbackFormMarkdown.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    isRequire: PropTypes.bool,
    priority: PropTypes.number,
    metadata: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
      markdown: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
    })).isRequired,
  }).isRequired,
  className: PropTypes.string,
};

FeedbackFormMarkdown.defaultProps = {
    className: '',
};

export default FeedbackFormMarkdown;
