/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const FEEDBACK_FORMS = gql`
  query FEEDBACK_FORMS($type: String!, $orderCode: String) {
    feedbackForms(type: $type, orderCode: $orderCode) {
      id
      title
      subTitle
      description
      submitButtonText
      cancelButtonText
      priority
      items {
        id
        type
        title
        subTitle
        isRequire
        priority
        metadata {
            key
            value
            label
            markdown
            accept
            uploadMaxFileSize
        }
      }
    }
  }
`;

export const SUBMIT_FEEDBACK_FORM = gql`
  mutation SUBMIT_FEEDBACK_FORM($data: SubmitFeedbackFormInput!, $orderCode: String) {
    submitFeedbackForm(data: $data, orderCode: $orderCode) {
      success
      message
      errors {
        feedbackFormItemId
        message
      }
    }
  }
`