const initialState = {
    isFeedbackFormsEnabled: false,
};

export default (state = initialState, { type, value }) => {
    switch (type) {
        case 'FEEDBACK_FORMS_ENABLED':
            return {
                ...state,
                isFeedbackFormsEnabled: value
            };
        default:
            return state;
    }
};
