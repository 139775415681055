import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Profile from '../../pages/Profile/Profile';
import AccountForm from '../../pages/AccountForm/AccountForm';
import OrderHistory from '../../pages/OrderHistory/OrderHistory';
import OrderDetails from '../../pages/OrderDetails/OrderDetails';
import ChangePassword from '../../pages/ChangePassword/ChangePassword';
import LoyaltyReward from '../../pages/LoyaltyReward/LoyaltyReward';
import OrderHelp from '../../pages/OrderHelp/OrderHelp';

const UserRoutes = () => {
  return (
    <Switch>
      <Route path="/user/profile" component={Profile} />
      <Route path="/user/create-account" component={AccountForm} />
      <Route path="/user/update-account" component={AccountForm} />
      <Route path="/user/order-history" component={OrderHistory} />
      <Route path="/user/change-password" component={ChangePassword} />
      <Route path="/user/order-details/:id" component={OrderDetails} />
      <Route path="/user/loyalty-rewards" component={LoyaltyReward} />
      <Route path="/user/order-help/:code" component={OrderHelp} />
      <Redirect to="/user/profile" />
    </Switch>
  );
};

export default UserRoutes;
