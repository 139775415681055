import {
    SET_DELIVERY_TIME_MUTATION,
    ADD_GIFT_RECIPIENT_MUTATION,
    REMOVE_ITEM_FROM_ORDER_MUTATION,
    ADD_DISCOUNT_MUTATION,
    REMOVE_DISCOUNT_MUTATION,
    ORDER_QUERY,
    ORDER_PRODUCT_OOS_QUERY,
    CREATE_ORDER_CONTACT_MUTATION,
    EDIT_ORDER_CONTACT_DETAILS_MUTATION,
    UPDATE_PRODUCT_ORDER_MUTATION,
    UPDATE_GIFT_PRODUCT_TO_ORDER_MUTATION,
    REMOVE_CONTACT_DETAILS_MUTATION,
    ADD_PRODUCT_TO_ORDER_MUTATION,
    ADD_GIFT_PRODUCT_TO_ORDER_MUTATION,
    ORDER_USE_COOKIE_DOUGH_MUTATION,
    ORDER_TRACKER_QUERY,
    SET_ORDER_TIP_MUTATION,
    SET_PAYMENT_METHOD_MUTATION,
    COMPLETE_ORDER_MUTATION,
    COMPLETE_ORDER_MUTATION_V2,
    GENERATE_ORDER_OTP_MUTATION,
    CREATE_CART_MUTATION,
    UPDATE_ORDER_LOCATION,
    ORDER_CONFIRMATION_QUERY,
    SET_ORDER_SHIPPING_ADDRESS,
    SET_ORDER_SHIPPING_METHOD,
    VIEW_ORDER_DETAILS_QUERY, 
    UPDATE_ORDER_COMMENT,
    ADD_PRODUCT_TO_UPSELL_FEATURED_MUTATION,
    DRIVER_LOCATION_QUERY,
    UPDATE_ORDER_INCLUDESPOON,
    ORDER_EVENT,
    UPDATE_ORDER_DETAILS,
    ORDER_ESTIMATE_QUERY,
} from '../../../graphql/order';

import { client } from "../../utilities/config";
import {store} from "../../../redux/store";
import {orderUpdated} from "../../../redux/actions/order";

/**
 * Updates the order redux store with updated order info
 *
 * @param {Promise} promise
 * @param {Array<String>} resultPath
 */
function updateOrder(promise, resultPath) {

    promise.then((result) => {

        let { data } = result;
        const { order } = store.getState();

        resultPath.forEach((path) => {
            data = data[path];
        });

        const newOrder = {...order, ...data};
        store.dispatch(orderUpdated(newOrder));
    }).catch(() => {});
}

export const removeProducts = async (orderCode, orderProductIds) => {
    const promise = client.mutate({
        mutation: REMOVE_ITEM_FROM_ORDER_MUTATION,
        variables: {
            orderCode,
            data: orderProductIds
        }
    });

    updateOrder(promise, ['removeOrderItemsFromOrder']);

    return promise;
};

/**
 * Updates the order with a new location/address
 *
 * @param {Object} data {
 *     orderCode: String!,
 *     orderTypeId: Int,
 *     storeId: ID,
 *     address: {
 *         address1: String!,
 *         city: String!,
 *         state: String!,
 *         postcode: String!
 *         lat: Float!
 *         lng: Float!
 *     },
 *     shippingMethod: ID,
 * }
 * @returns {Promise<FetchResult<any>>}
 */
export async function updateOrderLocation(orderCode, data) {

    const promise = client.mutate(
        {
            mutation: UPDATE_ORDER_LOCATION,
            variables: {
                orderCode,
                data
            }
        }
    );

    updateOrder(promise, ['updateOrder']);

    return promise;
}

export function addGiftcardOrPromotion(orderCode, couponIds, orderParams) {
    const promise = client.mutate(
        {
            mutation: ADD_DISCOUNT_MUTATION,
            variables: {
                orderCode,
                couponIds,
                orderParams
            }
        }
    );

    updateOrder(promise, ['addDiscountToOrder']);

    return promise;
}

export function getOrder(orderCode, orderParams) {
    const promise = client.query(
        {
            fetchPolicy: 'no-cache',
            query: ORDER_QUERY,
            variables: {
                orderCode,
                orderParams
            }
        }
    );

    updateOrder(promise, ['order'])

    return promise;
}

export function getOrderEstimate(orderCode) {
    const promise = client.query(
        {
            fetchPolicy: 'no-cache',
            query: ORDER_ESTIMATE_QUERY,
            variables: {
                orderCode,
            }
        }
    );

    updateOrder(promise, [])

    return promise;
}

export const orderProductsAvailability= (orderCode) => {
    return client.query({
        fetchPolicy: 'no-cache',
        query: ORDER_PRODUCT_OOS_QUERY,
        variables: {
            orderCode
        }
    })
}

export function removeDiscount(orderCode, couponIds, orderParams) {
    const promise = client.mutate(
        {
            mutation: REMOVE_DISCOUNT_MUTATION,
            variables: {
                orderCode,
                couponIds,
                orderParams
            }
        }
    );

    updateOrder(promise, ['removeDiscountFromOrder']);

    return promise;
}

export const setDeliveryPickupTime = (orderCode, selectedDeliveryTime) => {
    const promise = client.mutate({
        mutation: SET_DELIVERY_TIME_MUTATION,
        fetchPolicy: 'no-cache',
        variables: {
            orderCode,
            deliveryTime: selectedDeliveryTime
        }
    });

    return promise;
};

/**
 * @param {Object} data {
 *     firstName: String,
 *     lastName: String,
 *     phone: String,
 *     message: String
 * }
 */
export const addGiftRecipient = async (orderCode, data) => {
    const promise = client.mutate({
        mutation: ADD_GIFT_RECIPIENT_MUTATION,
        variables: {
            orderCode,
            data
        }
    });

    updateOrder(promise, ['addGiftRecipientDetailsToOrder']);

    return promise;
};

/**
 * @param {Object} data {
 *     firstName: String,
 *     lastName: String,
 *     email: String,
 *     phone: String
 * }
 */
export const addSender = async (orderCode, data) => {
    const promise = client.mutate({
        mutation: CREATE_ORDER_CONTACT_MUTATION,
        fetchPolicy: 'no-cache',
        variables: {
            orderCode,
            data
        }
    });

    updateOrder(promise, ['createCustomerContactOnOrder']);

    return promise;
};

/**
 * @param {Object} data {
 *     firstName: String,
 *     lastName: String,
 *     email: String,
 *     phone: String
 * }
 */
export const updateSender = (orderId, data) => {
    const promise = client.mutate({
        mutation: EDIT_ORDER_CONTACT_DETAILS_MUTATION,
        variables: {
            orderId,
            data
        }
    });

    updateOrder(promise, ['updateCustomerContact']);

    return promise;
};

/**
 * @param {Object} data {
 *     firstName: String,
 *     lastName: String,
 *     email: String,
 *     phone: String
 * }
 */
export const removeSender = orderCode => {
    const promise = client.mutate({
        mutation: REMOVE_CONTACT_DETAILS_MUTATION,
        variables: {
            orderCode
        }
    });

    updateOrder(promise, ['updateOrder']);

    return promise;
};

/**
 * @param {Object} data {
 *     orderProductId: Int,
 *     product: Int,
 *     quantity: Int,
 *     productOptions: [
 *         Int
 *     ]
 * }
 */
export const addUpdateOrderProduct = (orderCode, data) => {
    const promise = client.mutate({
        mutation: UPDATE_PRODUCT_ORDER_MUTATION,
        variables: {
            orderCode,
            data
        }
    });

    updateOrder(promise, ['updateProductOrder']);

    return promise;
};

export const updateOrderGiftProduct = (orderCode, data) => {
    const promise = client.mutate({
        mutation: UPDATE_GIFT_PRODUCT_TO_ORDER_MUTATION,
        variables: {
            orderCode,
            data
        }
    });

    updateOrder(promise, ['updateOrderGiftProduct']);

    return promise;
};

/**
 * @param {Object} order {
 *     code: String,
 *     useCookieDough: Boolean
 * }
 */
export const updateCookieDough = order => {
    const { useCookieDough, code } = order;

    const promise = client.mutate({
        mutation: ORDER_USE_COOKIE_DOUGH_MUTATION,
        variables: {
            orderCode: code,
            useCookieDough: !useCookieDough
        }
    });

    updateOrder(promise, ['updateOrder']);

    return promise;
};

export const setTip = async (orderCode, tip) => {
    const promise = client.mutate({
        mutation: SET_ORDER_TIP_MUTATION,
        fetchPolicy: 'no-cache',
        variables: {
            orderCode,
            tip
        }
    });

    await updateOrder(promise, ['updateOrder']);

    return promise;
};

/**
 * Completes Order
 *
 * @param {string} orderCode
 * @param {Int|String} paymentCredential
 * @param {string} orderOtp
 * @param {string} address2
 * @param {boolean} alertSenderPhone
 * @param {*[]} alertRecipientPhone
 * @param {string[]}subscriptionIds
 */
export const completeOrder = (orderCode, paymentCredential, orderOtp, address2, alertSenderPhone = false, alertRecipientPhone = false, subscriptionIds = []) => {
    const variables = {
        orderCode,
        paymentCredential,
        alertSenderPhone,
        alertRecipientPhone,
        subscriptionIds
    };

    if (orderOtp !== null) {
        variables.orderOtp = orderOtp
    }
    if (address2 !== null) {
        variables.address2 = address2
    }
    const promise = client.mutate({
        mutation: COMPLETE_ORDER_MUTATION,
        variables
    });

    return promise;
};

/**
 * Completes Order
 *
 * @param {string} orderCode
 * @param {string} deliveryTime
 * @param {Object} contact
 * @param {Object} recipient
 * @param {String} comment
 * @param {Int|String} paymentCredential
 * @param {string} orderOtp
 * @param {string} address2
 * @param {boolean} alertSenderPhone
 * @param {*[]} alertRecipientPhone
 * @param {string[]}subscriptionIds
 * @param {Number} tip
 * @param {boolean} includeSpoon
 */
export const completeOrderV2 = (orderCode, deliveryTime, contact, recipient, comment, paymentCredential, orderOtp, address2, alertSenderPhone = false, alertRecipientPhone = false,
    subscriptionIds = [], tip = 0, includeSpoon, isContactlessDelivery) => {
    const variables = {
        orderCode,
        deliveryTime,
        contact,
        paymentCredential,
        alertSenderPhone,
        alertRecipientPhone,
        subscriptionIds,
        recipient,
        comment,
        tip,
        includeSpoon,
        isContactlessDelivery
    };

    if (orderOtp !== null) {
        variables.orderOtp = orderOtp
    }
    if (address2 !== null) {
        variables.address2 = address2
    }
    const promise = client.mutate({
        mutation: COMPLETE_ORDER_MUTATION_V2,
        variables
    });

    return promise;
};

/**
 * Genereate New OTP for Order.
 *
 * @param {string} orderCode
 */
export const generateOrderOtp = (orderCode) => {
    const variables = {
        orderCode
    };

    const promise = client.mutate({
        mutation: GENERATE_ORDER_OTP_MUTATION,
        variables
    });

    return promise;
};

/**
 *
 * @param {string} orderCode
 * @param {Int|string} paymentMethodId
 */
export const setPaymentMethod = (orderCode, paymentMethodId) => {
    const promise = client.mutate({
        mutation: SET_PAYMENT_METHOD_MUTATION,
        variables: {
            orderCode,
            paymentMethodId
        }
    });

    updateOrder(promise, ['updateOrder']);

    return promise;
};

/**
 * @param orderCode
 * @param {Object} data {
 *     product: ID
 *     quantity: int
 * }
 */
export const addProductToOrder = (orderCode, data) => {
     const promise = client.mutate({
         mutation: ADD_PRODUCT_TO_ORDER_MUTATION,
         variables: {
             orderCode,
             data
         }
     });

    updateOrder(promise, ['addProductToOrderV2']);

    return promise;
};

/**
 * @param {Object} data {
 *     productId: int
 *     type: string
 * }
 */
 export const addProductToUpsellFeatured = (orderCode, data) => {
    const promise = client.mutate({
        mutation: ADD_PRODUCT_TO_UPSELL_FEATURED_MUTATION,
        variables: {
            orderCode,
            data
        }
    });

   return promise;
};

export const addGiftProductToOrder = (orderCode, data) => {
     const promise = client.mutate({
         mutation: ADD_GIFT_PRODUCT_TO_ORDER_MUTATION,
         variables: {
             orderCode,
             data
         }
     });

    updateOrder(promise, ['addGiftProductToOrder']);

    return promise;
};

/**
 * @param {Object} data {
 *     orderTypeId: Int!,
 *     shippingMethod: Int!,
 *     storeId: ID!,
 *     address: {
 *         address1: String!,
 *         city: String!,
 *         state: String!,
 *         postcode: String!,
 *         lat: Float!,
 *         lng: Float!
 *     }
 * }
 */
export const createCart = data => {
    const promise = client.mutate({
        mutation: CREATE_CART_MUTATION,
        variables: data
    });

    return promise;
}

export const getTracker = trackingID => {
    return client.query({
        query: ORDER_TRACKER_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
            trackingID,
        }
    });
};

export const getConfirmedOrder = orderCode => {
    return client.query({
        query: ORDER_CONFIRMATION_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
            orderCode
        }
    });
};

export const setShippingAddress = (orderCode, shippingAddressExternalId) => {
    const promise = client.mutate({
        fetchPolicy: 'no-cache',
        mutation: SET_ORDER_SHIPPING_ADDRESS,
        variables: {
            orderCode,
            shippingAddressExternalId
        }
    });

    updateOrder(promise, ['updateOrder']);

    return promise;
};

export const setShippingMethod = (orderCode, shippingMethodId) => {
    const promise = client.mutate({
        fetchPolicy: 'no-cache',
        mutation: SET_ORDER_SHIPPING_METHOD,
        variables: {
            orderCode,
            shippingMethodId
        }
    });

    updateOrder(promise, ['updateOrder']);

    return promise;
};

export const viewOrderDetails = orderCode => {
    const promise =  client.mutate({
        fetchPolicy: 'no-cache',
        mutation: VIEW_ORDER_DETAILS_QUERY,
        variables: {
            orderCode
        }
    });

    updateOrder(promise, ['order']);

    return promise;
};

export const updateOrderComment = async (orderCode, comment) => {
    const promise = client.mutate({
        fetchPolicy: 'no-cache',
        mutation: UPDATE_ORDER_COMMENT,
        variables: {
            orderCode,
            comment
        }
    });

    updateOrder(promise, ['updateOrder']);

    return promise;
}

export const getDriverLocation = orderId => {
    return client.query({
        query: DRIVER_LOCATION_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
            orderId,
        }
    });
};
export const updateOrderIncludeSpoon = async (orderCode, includeSpoon) => {
    const promise = client.mutate({
        fetchPolicy: 'no-cache',
        mutation: UPDATE_ORDER_INCLUDESPOON,
        variables: {
            orderCode,
            includeSpoon
        }
    });

    updateOrder(promise, ['updateOrder']);

    return promise;
}

export const updateOrderDetails = async (orderCode, updateOrderDetailsData) => {
    const promise = client.mutate({
        fetchPolicy: 'no-cache',
        mutation: UPDATE_ORDER_DETAILS,
        variables: {
            orderCode,
            data: {
                ...updateOrderDetailsData
            }
        }
    });

    return promise;
}

export const orderEvent = async (eventName, orderCode, eventProperties) => {
    const promise = client.mutate({
        fetchPolicy: 'no-cache',
        mutation: ORDER_EVENT,
        variables: {
            eventName,
            orderCode,
            eventProperties
        }
    });

    // updateOrder(promise, ['brazeCheckoutStarted']);

    return promise;
}
