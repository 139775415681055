/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import styles from "./Accessibility.module.css";
import { reInitAudioEye } from "../../shared/utilities/audioEye";

class Accessibility extends Component {
  componentDidMount() {
    reInitAudioEye();
  }

  render() {
    return (
      <div className={styles.pageWrapper}>
        <div className={`${styles.pageContainer} text-dark dark:text-white`}>
          <div className={styles.pageSection}>
            <h1>Accessibility Statement</h1>

            <p>
              This Accessibility Statement applies to:{" "}
              <a href="https://insomniacookies.com">
                https://insomniacookies.com
              </a>
            </p>
          </div>

          <div className={styles.pageSection}>
            <h3>We Value Digital Inclusion</h3>
            <p>
              Insomnia Cookies, LLC (&ldquo;Insomnia Cookies&ldquo;) is committed to
              maintaining and increasing digital accessibility on its website
              and mobile application (&ldquo;Digital Properties&ldquo;) to ensure all of its
              customers can fully access and enjoy its online offerings, goods,
              and services. Insomnia Cookies strives to comply with Americans
              with Disabilities Act Title III (“ADA”) and WCAG 2.1 (Levels A and
              AA) requirements to ensure a positive customer experience.
            </p>
            <p style={{ fontStyle: "italic", marginTop: "12px" }}>
              The Insomnia Cookies Digital Properties are monitored and tested
              regularly by internal resources and third-party accessibility
              consultants to ensure Insomnia Cookies is maintaining the
              accessibility of its Digital Properties and implementing any
              necessary remedial measures.
            </p>
          </div>
          <div className={styles.pageSection}>
            <h3>Feedback and Accessibility Contact Information</h3>

            <p>
              We value the input of our customers on how we can improve the
              accessibility of our Digital Properties. If you have any
              suggestions for improving accessibility or if you encounter any
              accessibility issues while navigating our Digital Properties,
              please contact our Accessibility Team at
              accessibility@insomniacookies.com or 877-632-6654
            </p>
          </div>

          <div className={styles.pageSection}>
            <h3>Third-Party Sites</h3>

            <p>
              On Insomnia Cookies&lsquo; Digital Properties, Insomnia Cookies may make
              use of third-party websites such as Instagram, YouTube, Twitter,
              and Facebook to spread news and information about Insomnia
              Cookies&lsquo; products and services. These websites, which are not
              controlled by Insomnia Cookies, may present challenges for
              individuals with disabilities that we are not able to control or
              remedy. We encourage all customers to review the accessibility
              policies set forth on any third-party website.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Accessibility;
