/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

const orderResponse = `
      id
      code
      isContactlessDelivery
      invoiceDate
      isCancelled
      isRefunded
      isCooledDown
      isStale
      shippingMethodMinAmount
      deliveryTime
      orderMethod
      deliveryEstimate
      deliveryStatus {
        status
        lat
        lon
        driverName
        estimatedPickupTime
        estimatedPickupTimeMax
        estimatedDropoffTime
        estimatedDropoffTimeMax
        deliveryImageUrl
      }
      loyaltyMultiplier
      createdAt
      comment
      includeSpoon
      status {
        status
      }
      store {
        id
        address
      }
      orderRedeemables {
        id,
        order_id,
        type,
        loyalty_provider_id,
        order_product_id,
        external_id,
        amount,
        points,
        redemption_group
      }
      useCookieDough
      upsellProducts {
        id
        title
        productImage
        price
        hasAssortments
        options {
          id
        }
      }
      contactDetails {
        id
        firstName
        lastName
        phone
        email
      }
      isGift
      paymentMethod {
        id
        title
      }
      giftRecipientContactDetails {
        id
        firstName
        phone
        email
      }
      comment
      sender {
        firstName
        lastName
        phone
        email
      }
      recipient {
        firstName
        lastName
        phone
      }
      customer {
        id
        cards {
          transactionId
          last4
          exp
          cardType
          favorite
          paymentMethodId
          paymentProcessorId
        }
        contacts {
          id
          firstName
          lastName
          phone
        }
        firstname
        lastname
        telephone
        telephoneNew
        phoneTelephone
        prefixTelephone
        loyalty {
          rewards
        }
      }
      items {
        id
        quantity
        price
        product {
          id
          title
          categories {
            id
            title
            slug
            parentId
          }
          hasAssortments
          price
          productImageSmall
        }
        boxOptions {
          id
          title
          price
        }
        productOptions {
          id
          title
          quantity
          optionPrice
          groupOptionId
          options {
            price
            id
          }
          productOption {
            id
            price
          }
        }
      }
      discounts {
        title
        value
      }
      tax
      tip
      subtotal
      total
      priceWithTax
      totals {
        title
        value
      }
      orderTypeId
      shippingMethod {
        id
        title
      }
      shippingAddress2
      shippingAddress {
        address1
        city
        lat
        lng
        postcode
        state
      }
      customer {
        id
        firstname
        lastname
        telephone
        telephoneVerified
        email
        telephoneVerificationRequired
        loyalty {
          rewards
        }
      }
      orderExperiences {
        source
        rating
        comment
        metaTags {
          id
          title
        }
      }
      store {
        id
        address
        lat
        lng
        phone
        name
        pickupEstimate
        deliveryEstimate
        currency {
            name,
            code,
            symbol
        }
        storePaymentMethods {
          id
          paymentMethodId
          storeId
          active
          paymentMethod {
            id
            title
            description
            variables
          }
          iconUrlPath
          buttonUrlPath
          isEmbeddedHtml
          embeddedJs
          embeddedHtml
          embeddedLink
          groupTitle
          uiComponentType
          theOrder
          variables {
            key
            value
          }
        }
      }
`;
const orderResponseV2 = `
      id
      code
      shippingMethodMinAmount
      isContactlessDelivery
      deliveryTime
      deliveryEstimate
      createdAt
      comment
      orderMethod
      includeSpoon
      store {
        id
        address
      }
      orderRedeemables {
        id,
        order_id,
        type,
        loyalty_provider_id,
        order_product_id,
        external_id,
        amount,
        points,
        redemption_group
      }
      useCookieDough
      upsellProducts {
        id
        title
        productImage
        price
        hasAssortments
        options {
          id
        }
      }
      paymentMethod {
        id
        title
      }
      sender {
        firstName
        lastName
        phone
        email
      }
      recipient {
        firstName
        lastName
        phone
      }
      customer {
        id
        cards {
          transactionId
          last4
          exp
          cardType
          favorite
          paymentMethodId
          paymentProcessorId
        }
        firstname
        lastname
        email
        telephone
        telephoneNew
        phoneTelephone
        prefixTelephone
        loyalty {
          rewards
        }
      }
      items {
        id
        quantity
        price
        product {
          id
          title
          categories {
            id
            title
            slug
            parentId
          }
          hasAssortments
          price
        }
        boxOptions {
          id
          title
          price
        }
        productOptions {
          id
          title
          quantity
          optionPrice
          groupOptionId
          options {
            price
            id
          }
          productOption {
            id
            price
          }
        }
      }
      discounts {
        title
        value
      }
      tip
      priceWithTax
      totals {
        title
        value
      }
      orderTypeId
      shippingMethod {
        id
        title
      }
      shippingAddress2
      shippingAddress {
        address1
        city
        state
        postcode
      }
      store {
        id
        address
        name
        pickupEstimate
        deliveryEstimate
        currency {
            name,
            code,
            symbol
        }
        storePaymentMethods {
          id
          paymentMethodId
          storeId
          active
          paymentMethod {
            id
            title
            description
            variables
          }
          iconUrlPath
          isEmbeddedHtml
          embeddedJs
          embeddedHtml
          embeddedLink
          groupTitle
          uiComponentType
          buttonUrlPath
          theOrder
          variables {
            key
            value
          }
        }
      }
`;
export const ORDER_QUERY = gql`
  query ORDER($orderCode: String!, $orderParams: OrderParamsInput) {
    order(orderCode: $orderCode, orderParams: $orderParams) {
        ${orderResponse}
    }
  }
`;

export const ORDER_CONFIRMATION_QUERY = gql`
  query ORDER_CONFIRMATION($orderCode: String!) {
    order(orderCode: $orderCode) {
        id
        code
        email
        phone,
        firstName
        lastName
        orderMethod
        orderTypeId
        loyaltyPoints
        shippingAddress2
        isContactlessDelivery
        shippingAddress {
          address1
          city
          state
          postcode
          lat
          lng
        }
        shippingFirstName
        shippingLastName
        shipment {
          trackingNumber
          shippingMethod
          estimatedDeliveryDate
          shippingLocation {
            lat
            lng
            shipper
            addressOne
            addressTwo
            city
            state
            zip
          }
          tracking {
            location
            time
            description
          }
        }
        trackingID
        deliveryTime
        deliveryStatus {
          status
          lat
          lon
          driverName
          estimatedPickupTime
          estimatedPickupTimeMax
          estimatedDropoffTime
          estimatedDropoffTimeMax
          deliveryImageUrl
        }
        status {
          status
        }
        store {
          id
          address
          lat
          lng
          name
          phone
        }
        recipient {
          firstName
          lastName
          phone
        }
        comment
        includeSpoon
        customer {
          id
          loyalty {
              id
          }
        }
        customerAccountStatus{
          signIn
          signUp
        }
    }
  }
`;

export const ORDER_TRACKER_QUERY = gql`
  query ORDER_TRACKER_QUERY($trackingID: String!) {
    orderTracker(trackingID: $trackingID) {
        id
        code
        email
        orderTypeId
        shippingFirstName
        shippingLastName
        orderMethod
        shipment {
          trackingNumber
          shippingMethod
          estimatedDeliveryDate
          shippingLocation {
            lat
            lng
            shipper
            addressOne
            addressTwo
            city
            state
            zip
          }
          tracking {
            location
            time
            description
          }
        }
        shippingAddress2
        shippingAddress {
          address1
          city
          state
          postcode
          lat
          lng
        }
        trackingID
        deliveryTime
        deliveryStatus {
          status
          lat
          lon
          driverName
          estimatedPickupTime
          estimatedPickupTimeMax
          estimatedDropoffTime
          estimatedDropoffTimeMax
          deliveryImageUrl
        }
        status {
          status
        }
        store {
          id
          address
          lat
          lng
          name
          phone
        }
        recipient {
            firstName
            lastName
            phone
        }
        comment
        includeSpoon
    }
  }
`;

export const ORDER_ESTIMATE_QUERY = gql`
  query ORDER_ESTIMATE($orderCode: String!) {
    orderEstimate(orderCode: $orderCode) {
      estimate
    }
  }
`;

export const CREATE_CART_MUTATION = gql`
  mutation CREATE_CART(
    $storeId: ID!
    $orderTypeId: Int!
    $shippingMethod: ID!
    $address: AddressDetailsInput
  ) {
    createCart(
      data: {
        storeId: $storeId
        orderTypeId: $orderTypeId
        shippingMethod: $shippingMethod
        address: $address
      }
    ) {
      code,
      shippingMethodMinAmount
    }
  }
`;

export const ADD_GIFT_PRODUCT_TO_ORDER_MUTATION = gql`
  mutation ADD_GIFT_PRODUCT_TO_ORDER_MUTATION(
    $orderCode: String!
    $data: OrderGiftItemInput!
  ) {
    addGiftProductToOrder(orderCode: $orderCode, data: $data) {
      code
      items {
        quantity
        id
        price
        product {
          id
          title
          categories {
            id
            title
            slug
            parentId
          }
          hasAssortments
          price
        }
        productOptions {
          id
          title
          quantity
          groupOptionId
          value
          options {
            id
          }
          productOption {
            id
            price
            label
          }
        }
        boxOptions {
          id
          title
        }
      }
    }
  }
`

export const ADD_PRODUCT_TO_ORDER_MUTATION = gql`
  mutation ADD_PRODUCT_TO_ORDER_MUTATION(
    $orderCode: String!
    $data: OrderProductInput!
  ) {
    addProductToOrderV2(orderCode: $orderCode, data: $data) {
      ${orderResponseV2}
    }
  }
`;

export const ADD_PRODUCT_TO_UPSELL_FEATURED_MUTATION = gql`
  mutation ADD_PRODUCT_TO_UPSELL_FEATURED_MUTATION($orderCode: String! $data: UpsellFeaturedInput!) {
    addProductToUpsellFeatured(orderCode: $orderCode, data: $data)
  }
`;

export const REMOVE_ITEM_FROM_ORDER_MUTATION = gql`
  mutation REMOVE_ITEM_FROM_ORDER_MUTATION($orderCode: String!, $data: [ID!]!) {
    removeOrderItemsFromOrder(orderCode: $orderCode, data: $data) {
      id
      code
    }
  }
`;

export const UPDATE_PRODUCT_ORDER_MUTATION = gql`
  mutation UPDATE_PRODUCT_ORDER_MUTATION($orderCode: String!, $data: OrderItemInput!) {
    updateProductOrder(orderCode: $orderCode, data: $data) {
      ${orderResponse}
    }
  }
`;

export const UPDATE_GIFT_PRODUCT_TO_ORDER_MUTATION = gql`
  mutation UPDATE_GIFT_PRODUCT_TO_ORDER_MUTATION(
    $orderCode: String!
    $data: OrderGiftItemInput!
  ) {
    updateOrderGiftProduct(orderCode: $orderCode, data: $data) {
      code
      items {
        quantity
        id
        price
        product {
          id
          title
          categories {
            id
            title
            slug
            parentId
          }
          hasAssortments
          price
        }
        productOptions {
          id
          title
          quantity
          groupOptionId
          value
          options {
            id
          }
          productOption {
            id
            price
            label
          }
        }
        boxOptions {
          id
          title
        }
      }
    }
  }
`

export const VIEW_ORDER_DETAILS_QUERY = gql`
  query VIEW_ORDER_DETAILS_QUERY($orderCode: String!) {
    order(orderCode: $orderCode) {
      code
      loyaltyMultiplier
      orderMethod
      store {
        id
        address
      }
      upsellProducts {
        id
        title
        productImage
        price
        hasAssortments
        options {
          id
        }
      }
      orderRedeemables {
        id,
        order_id,
        type,
        loyalty_provider_id,
        order_product_id,
        external_id,
        amount,
        points,
        redemption_group
      }
      items {
        id
        quantity
        price
        product {
          id
          title
          categories {
            id
            title
            slug
            parentId
          }
          hasAssortments
          price
        }
        boxOptions {
          id
          title
          price
        }
        productOptions {
          id
          title
          quantity
          value
          options {
            price
          }
          productOption {
            id
            price
            label
          }
        }
      }
      discounts {
        title
        value
      }
      priceWithTax
      totals {
        title
        value
      }
      orderTypeId
    }
  }
`;

export const ORDER_PRODUCT_OOS_QUERY = gql`
  query  ORDER_PRODUCT_OOS_QUERY($orderCode: String! ){
    productAvailabilityCheck(orderCode : $orderCode) {
        id
        title
    }
  }
`;
export const ADD_GIFT_RECIPIENT_MUTATION = gql`
  mutation ADD_GIFT_RECIPIENT_MUTATION($orderCode: String!, $data: GiftRecipientDetails) {
    addGiftRecipientDetailsToOrder(orderCode: $orderCode, data: $data) {
      code
    }
  }
`;
export const SET_ORDER_CONTACT_DETAILS_MUTATION = gql`
  mutation updateOrder($orderCode: String!, $contactId: ID!) {
    updateOrder(
      orderCode: $orderCode
      data: { contactDetails: { connect: { id: $contactId } } }
    ) {
        ${orderResponse}
    }
  }
`;

export const SET_ORDER_SHIPPING_ADDRESS = gql`
  mutation updateOrder($orderCode: String!, $shippingAddressExternalId: String!) {
    updateOrder(
      orderCode: $orderCode
      data: { shippingAddressExternalId: $shippingAddressExternalId }
    ) {
        ${orderResponse}
    }
  }
`;

export const CREATE_ORDER_CONTACT_MUTATION = gql`
  mutation CREATE_ORDER_CONTACT_MUTATION(
    $orderCode: String!,
    $data: CartContactInput!
  ) {
    createCustomerContactOnOrder(orderCode: $orderCode, data: $data) {
      ${orderResponse}
    }
  }
`;

export const EDIT_ORDER_CONTACT_DETAILS_MUTATION = gql`
  mutation EDIT_ORDER_CONTACT_DETAILS(
    $id: ID!,
    $data: CartContactInput!
  ) {
    updateCustomerContact(where: $id, data: $data) {
      code
      firstName
      lastName
      email
      phone
    }
  }
`;

export const REMOVE_CONTACT_DETAILS_MUTATION = gql`
  mutation REMOVE_CONTACT_DETAILS_MUTATION($orderCode: String!) {
    updateOrder(
      orderCode: $orderCode
      data: { contactDetails: { delete: true } }
    ) {
        ${orderResponse}
    }
  }
`;

export const SET_PAYMENT_METHOD_MUTATION = gql`
  mutation SET_PAYMENT_METHOD_MUTATION($orderCode: String!, $paymentMethodId: ID!) {
    updateOrder(
      orderCode: $orderCode
      data: { paymentMethodId: $paymentMethodId }
    ) {
        ${orderResponse}
    }
  }
`;

export const SET_DELIVERY_TIME_MUTATION = gql`
  mutation SET_DELIVERY_TIME_MUTATION($orderCode: String!, $deliveryTime: DateTime!) {
    updateOrder(
      orderCode: $orderCode
      data: { deliveryTime: $deliveryTime }
    ) {
        ${orderResponse}
    }
  }
`;

export const CREATE_ORDER_PAYMENT_MUTATION = gql`
  mutation CREATE_ORDER_PAYMENT_MUTATION(
    $orderCode: String!
    $data: CustomerPaymentMethodInput!
  ) {
    createCustomerPaymentMethodOnOrder(orderCode: $orderCode, data: $data) {
      code
      cvv
      cardNumber
        name
      postcode
      expirationDate
    }
  }
`;

export const EDIT_PAYMENT_METHOD_MUTATION = gql`
  mutation EDIT_PAYMENT_METHOD_MUTATION(
    $paymentId: ID!
    $data: CustomerPaymentMethodUpdateInput!
  ) {
    updateCustomerPaymentMethod(where: { id: $paymentId }, data: $data) {
      code
      cvv
      cardNumber
        name
      postcode
      expirationDate
    }
  }
`;

export const REMOVE_PAYMENT_METHOD_MUTATION = gql`
  mutation REMOVE_PAYMENT_METHOD_MUTATION($orderCode: String!) {
    updateOrder(
      orderCode: $orderCode
      data: { paymentMethod: { delete: true } }
    ) {
      code
      paymentMethod  {
        id
        title
      }
      customer {
        id
        paymentMethods {
          id
          name
          cardNumber
          cvv
          expirationDate
          postcode
        }
      }
    }
  }
`;

export const ORDER_USE_COOKIE_DOUGH_MUTATION = gql`
  mutation updateOrder($orderCode: String!, $useCookieDough: Boolean!) {
    updateOrder(orderCode: $orderCode, data: { useCookieDough: $useCookieDough }) {
      ${orderResponse}
    }
  }
`;

export const SET_ORDER_TIP_MUTATION = gql`
  mutation updateOrder($orderCode: String!, $tip: Float!) {
    updateOrder(orderCode: $orderCode, data: { tip: $tip }) {
      ${orderResponse}
    }
  }
`;

export const SET_ORDER_SHIPPING_METHOD = gql`
    mutation setOrderShippingMethod($orderCode: String!, $shippingMethodId: ID!) {
        updateOrder(orderCode: $orderCode, data: { shippingMethod: $shippingMethodId }) {
            ${orderResponse}
        }
    }
`;

export const SUBMIT_ORDER_MUTATION = gql`
  mutation submitOrder($orderCode: String!) {
    submitOrder(orderCode: $orderCode) {
      code
  }
}
`;

export const ADD_DISCOUNT_MUTATION = gql`
  mutation addDiscountToOrder($orderCode: String!, $couponIds: [String], $orderParams: OrderParamsInput) {
    addDiscountToOrder(orderCode: $orderCode, couponIds: $couponIds, orderParams: $orderParams) {
      totals {
        title
        value
      }
      discounts {
        title
        value
      }
    }
  }
`;

export const REMOVE_DISCOUNT_MUTATION = gql`
  mutation removeDiscountFromOrder($orderCode: String!, $couponIds: [String], $orderParams: OrderParamsInput) {
    removeDiscountFromOrder(orderCode: $orderCode, couponIds: $couponIds, orderParams: $orderParams) {
      totals {
        title
        value
      }
      discounts {
        title
        value
      }
    }
  }
`;

export const COMPLETE_ORDER_MUTATION = gql`
  mutation completeOrder($orderCode: String!, $paymentCredential: PaymentCredential, $orderOtp: String, $address2: String, $alertSenderPhone: Boolean, $alertRecipientPhone: Boolean, $subscriptionIds: [String]) {
    completeOrder(orderCode: $orderCode, paymentCredential: $paymentCredential, orderOtp: $orderOtp, address2: $address2, alertSenderPhone: $alertSenderPhone, alertRecipientPhone: $alertRecipientPhone, subscriptionIds: $subscriptionIds)
  }
`;

export const COMPLETE_ORDER_MUTATION_V2 = gql`
  mutation completeOrderV2($orderCode: String!, $paymentCredential: PaymentCredential, $orderOtp: String, $address2: String, $alertSenderPhone: Boolean, $alertRecipientPhone: Boolean, $subscriptionIds: [String], $deliveryTime: DateTime, $contact: CartContactInput!, $recipient: GiftRecipientDetails, $comment: String, $tip: Float, $includeSpoon: Boolean, $isContactlessDelivery: Boolean) {
    completeOrderV2(orderCode: $orderCode, paymentCredential: $paymentCredential, orderOtp: $orderOtp, address2: $address2, alertSenderPhone: $alertSenderPhone, alertRecipientPhone: $alertRecipientPhone, subscriptionIds: $subscriptionIds, deliveryTime: $deliveryTime, contact: $contact, recipient: $recipient, comment: $comment, tip: $tip, includeSpoon: $includeSpoon, isContactlessDelivery: $isContactlessDelivery)
  }
`;

export const GENERATE_ORDER_OTP_MUTATION = gql`
  mutation generateOrderOtp($orderCode: String!) {
    generateOrderOtp(orderCode: $orderCode)
  }
`;

export const UPDATE_ORDER_LOCATION = gql`
  mutation updateOrder($orderCode: String!, $data: updateOrderInput!) {
    updateOrder(orderCode: $orderCode, data: $data) {
      code
      orderTypeId
      storeId
      shippingMethodMinAmount
    }
  }
`;

export const UPDATE_ORDER_COMMENT = gql`
    mutation updateOrder($orderCode: String!, $comment: String!) {
      updateOrder(orderCode: $orderCode, data: { comment: $comment }) {
        comment
      }
    }
`;

export const DRIVER_LOCATION_QUERY = gql`
  query DRIVER_LOCATION_QUERY($orderId: String!) {
    driverLocation(orderId: $orderId) {
      lat
      lng
      status
    }
  }
`;

export const UPDATE_ORDER_INCLUDESPOON = gql`
    mutation updateOrder($orderCode: String!, $includeSpoon: Boolean!) {
      updateOrder(orderCode: $orderCode, data: { includeSpoon: $includeSpoon }) {
        includeSpoon
      }
    }
`;

export const UPDATE_ORDER_DETAILS = gql`
    mutation updateOrder($orderCode: String!, $data: updateOrderInput!) {
      updateOrder(orderCode: $orderCode, data: $data) {
        deliveryTime
        deliveryStatus {
          status
          lat
          lon
          estimatedPickupTime
          estimatedPickupTimeMax
          estimatedDropoffTime
          estimatedDropoffTimeMax
          driverName
          deliveryImageUrl
        }
        includeSpoon
        isContactlessDelivery
        shippingAddress2
        comment
        sender {
          firstName
          lastName
          phone
          email
        }
        recipient {
          firstName
          lastName
          phone
        }
      }
    }
`;

export const ORDER_EVENT = gql`
    mutation orderEvent($eventName: String!, $orderCode: String!, $eventProperties: eventProperties!) {
      orderEvent(eventName: $eventName, orderCode: $orderCode, eventProperties: $eventProperties)
    }
`;
