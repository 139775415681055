import { historyAction } from '../constants/history';
import {isShippingStoreProduct} from "./store";
import {storeIds} from "../constants/store";
import {orderMethods} from "../constants/order";
import {guardOrderChange} from "./orders";
import { routeCountryPath } from './common';
import { UNAVAILABLE } from '../constants/product';
import endpoints from "../constants/endpoints";

/**
 * Handle add to cart button related states when any product accessed directly
 * @param component
 * @return void
 */
export const handleProductWithDirectLink = (component) => {
    const { product } = component.state;
    const { history, selectedStore, userCart, setStoreId, setUserOrderMethodId, updateProduct, setUserAddress, setUserCart, setUserCartId } = component.props;
   
    const availability = product?.availability;
   
    if (availability === UNAVAILABLE) {
        history.push(routeCountryPath(endpoints.homePage));
    }

    const directVisit = history.action === historyAction.pop;
    if (!directVisit) {
        return;
    }

    if (updateProduct) {
        return;
    }

    const isShippingProduct = isShippingStoreProduct(product.stores);

    const cartHasItem = (userCart.length > 0);

    const checkShippingProductSelectedStoreNull = (isShippingProduct && selectedStore === null);
    if (checkShippingProductSelectedStoreNull) {
        setStoreId({id: storeIds.shipping});
        setUserOrderMethodId(orderMethods.shipping);
    }

    const checkShippingProductSelectedStoreNotEmpty = ((isShippingProduct) && (selectedStore !== null && parseInt(selectedStore.id, 10) !== storeIds.shipping));
    if (checkShippingProductSelectedStoreNotEmpty && cartHasItem) {
        guardOrderChange(true, () =>  {
            setStoreId({id: storeIds.shipping});
            setUserOrderMethodId(orderMethods.shipping);
        }, null);
    }

    if (checkShippingProductSelectedStoreNotEmpty && !cartHasItem) {
        setUserCartId(null);
        setUserCart([]);
        setUserAddress("");
        setStoreId({id: storeIds.shipping});
        setUserOrderMethodId(orderMethods.shipping);
    }

    const checkNoneShippingProductEmptyCart = ((!isShippingProduct) && (selectedStore !== null && parseInt(selectedStore.id, 10) === storeIds.shipping));
    if (checkNoneShippingProductEmptyCart && !cartHasItem) {
        setStoreId(null);
        setUserOrderMethodId(null);
    }

    if (checkNoneShippingProductEmptyCart && cartHasItem) {
        guardOrderChange(true, () => {
            setStoreId(null);
            setUserOrderMethodId(null);
        }, null);
    }
};

export const getIconId = (icon) => {
  const regex = /https?:\/?\/?[^:/\s]+\/\w+.*\/([\w\-.]+)*\.\w+?$/;

  if (regex.test(icon)) {
    const iconId = icon.split(regex)[1];
    return iconId !== 'undefined' && iconId.length > 1 ? iconId.replace(/_/g, '-').replace(/\./g,'-'): '';
  }

  return '';
}

export const sortOptionByPreset = (options, customizeProducts) => {
  return options.map(option => {
    if (customizeProducts[option.id]) {
      const countQuantity = {};
      customizeProducts[option.id].forEach(x => {
        countQuantity[x] = (countQuantity[x] || 0) + 1;
      });
      // eslint-disable-next-line
      option.options = option.options?.map(item => {
        if (countQuantity[item.id]) {
          return { ...item, quantity: countQuantity[item.id] };
        }
        return { ...item, quantity: 0 };
      });

      option.options.sort((a, b) =>
        // eslint-disable-next-line
        a.quantity < b.quantity ? 1 : b.quantity < a.quantity ? -1 : 0
      );
    }
    return option;
  });
};
