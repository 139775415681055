import { FEEDBACK_FORMS, SUBMIT_FEEDBACK_FORM } from "../../../graphql/feedbackForm";
import { client } from "../../utilities/config";

export const getFeedbackForms = (type, orderCode = null) => {
  return client.query(
    {
      query: FEEDBACK_FORMS,
      variables: {
        type,
        orderCode,
      }
    }
  );
}

export const submitFeedbackForm = (data, orderCode = null) => {
  return client.mutate(
    {
      mutation: SUBMIT_FEEDBACK_FORM,
      variables: {
        data,
        orderCode,
      }
    }
  );
}
