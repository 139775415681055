/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const ALL_STORES_QUERY = gql`
  query ALL_STORES_QUERY {
        stores(where: { storeType: "regular" }) {
      name
    }
  }
`;

export const ALL_REGULAR_STORES_QUERY = gql`
    query ALL_STORES_QUERY {
        regularStores {
            name
        }
    }
`;

export const STORES_QUERY = gql`
  query STORES_QUERY {
    stores(where: { storeType: "regular" }) {
      name
    }
  }
`;

export const STORE_HOURS_QUERY = gql`
  query STORE_HOURS_QUERY($orderCode: String!, $date: Date!) {
    storeHours(orderCode: $orderCode, date: $date) {
      date
      hours,
      holiday,
      message
    }
  }
`;

export const STORE_QUERY = gql`
  query STORE_QUERY($id: ID!){
    store( where: { id: $id}) {
      id
      name
      slug
      address
      distanceToStore
      phone
      storefrontImage
      lat
      lng
      inDeliveryRange
      boundary
      status
      note
      storeType
      isPickupOpen
      isDeliveryOpen
      showContactless
      hours {
        type
        days {day, hour}
      }
      blurb
      promotionalText
      currency {
        id,
        name,
        code,
        symbol
      }
    }
  }
`;

export const STORE_QUERY_BY_LOCATION_NAME = gql`
  query STORE_QUERY($location_page_name: String!){
    storesByLocation( where: { LocationPageNameInput: $location_page_name}) {
      id
      name
      slug
      address
      distanceToStore
      phone
      storefrontImage
      lat
      lng
      inDeliveryRange
      showContactless
      boundary
      status
      note
      storeType
      isPickupOpen
      isDeliveryOpen
      hours {
        type
        days {day, hour}
      }
    }
  }
`;

export const STORE_CLOSE_TIMER_QUERY = gql`
  query STORE_CLOSE_TIMER_QUERY($orderCode: String!) {
    closeTimer(params: { orderCode: $orderCode }) {
      timerMessage
      timerValue
      timerShouldShow
    }
  }
`;
