/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable  jsx-a11y/interactive-supports-focus */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./FeedbackFormList.module.css";
import VectorSVG from "./imgs/vector.svg";

class FeedbackFormList extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { forms, onSelectForm } = this.props;
    return (
      <div className={styles.listContainer}>
        { forms.map((form) => {
          return (
            <div role="link" key={form.id} className={styles.item} onClick={() => { onSelectForm(form); }}>
              <div className={styles.title}>{form.title}</div>
              <img className="dark:image-negative" src={VectorSVG} alt="select" />
            </div>
          );
        }) }
      </div>
    );
  }
}

FeedbackFormList.propTypes = {
  forms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  })),
  onSelectForm: PropTypes.func,
};

FeedbackFormList.defaultProps = {
  forms: [],
  onSelectForm: () => {},
};

export default FeedbackFormList;
