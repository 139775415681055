/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const LOCATIONS_VIA_GEO_QUERY = gql`
  query locations($lat: String!, $lng: String!) {
    locationSearch(data: { coords: [$lat, $lng] }) {
      id
      address1
      lat
      lng
    }
  }
`;

export const LOCATIONS_VIA_ADDRESS_QUERY = gql`
  query locations($lat: Float, $lng: Float, $address: String!, $fullyQualified: Boolean, $showDorms: Boolean, $filter: String) {
    locationSearch(data: { address: $address, lat: $lat, lng: $lng, fullyQualified: $fullyQualified, showDorms: $showDorms, filter: $filter }) {
      id
      type {
        id
      }
      address1
      addressDetails{
        address1
        state
        city
      }
      externalId
      lat
      lng
      store {
        id
        name
        address
        phone
        storefrontImage
        lat
        lng
        boundary
        hours {
          type
          days {day, hour}
        }
      }
      addressDetails {
        address1
        house
        street
        city
        state
        country
        postcode
        lat
        lng
        dormId
      }
      postalCodeValue
    }
  }
`;

export const STORES_QUERY = gql`
  query stores($lat: Float, $lng: Float, $externalId: String, $orderTypeId: ID, $customAddress: String) {
    storeSearch(data: {
        lat: $lat,
        lng: $lng,
        externalId: $externalId,
        orderTypeId: $orderTypeId,
        customAddress: $customAddress
    }) {
      lat
      lng
      address {
        address1
        city
        state
        postcode
        lat
        lng
        countryId
        address2
      }
      stores {
        id
        countryId
        name
        slug
        address
        mailingAddress
        city
        state
        zip
        distanceToStore
        phone
        storefrontImage
        lat
        lng
        inDeliveryRange
        boundary
        status
        note
        storeType
        isPickupOpen
        isDeliveryOpen
        showContactless
        hours {
          type
          days {day, hour}
        }
        blurb
        promotionalText
      }
    }
  }
`;

export const CURRENT_LOCATION_COORDINATES = gql`
  query CURRENT_LOCATION_COORDINATES {
    currentLocationCoordinates {
      lat
      lng
    }
  }
`;
