/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import styles from "./Items.module.css";
import RemoveFileSVG from "../imgs/close.svg";
import AddFileSVG from "../imgs/addFile.svg";
import AddImageSVG from "../imgs/addImage.svg";
import RedInfoSVG from "../imgs/redInfo.svg";

class FeedbackFormFile extends Component {

  fileInputRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      values: [],
      errorMessage: '',
      uploadedFilesSize: 0,
    };
  }

  componentDidMount() {
    const { errorMessage } = this.props;
    this.setState({
      errorMessage,
    });
  }

  componentDidUpdate(prevProps) {
    const { errorMessage } = this.props;
    if (prevProps.errorMessage !== errorMessage) {
      this.setState({
        errorMessage,
      });
    }
  }

  saveValue = async (value, metadata) => {
    const { onInput } = this.props;
    const { values } = this.state;
    const resut = Array.from(value).map((file, key) => {
      const res = {
        key: key,
        value: file,
      };
      const fileReader = new FileReader();
      return new Promise((resolve) => {
        fileReader.onload = (e) => {
          res.content = e.target.result;
          values.push(res);
          resolve(e.target.result);
          fileReader.onload = null;
        }
        if (file.type.startsWith('image/')) {
          fileReader.readAsDataURL(file);
        } else {
          fileReader.readAsText(file);
        }
      });
    });
    await Promise.all(resut);
    let uploadedFilesSize = 0;
    const newValues = values.map((val, index) => {
      const res = {...val, key: index};
      uploadedFilesSize += res.value.size;
      return res;
    });
    this.setState({
      values: newValues,
      uploadedFilesSize,
    });
    this.checkFileSize(metadata.uploadMaxFileSize);
    onInput(newValues.map((vals) => {
      return {
        key: vals.key.toString(),
        value: vals.value,
      };
    }));
  }

  uploadFile = () => {
    this.fileInputRef.current.click();
  }

  removeFile = (index, metadata) => {
    const { onInput } = this.props;
    const { values } = this.state;
    values.splice(index, 1);
    let uploadedFilesSize = 0;
    const newValues = values.map((vals, key) => {
      const res = {...vals, key: key};
      uploadedFilesSize += res.value.size;
      return res;
    });
    this.setState({
      values: newValues,
      uploadedFilesSize,
    });
    this.checkFileSize(metadata.uploadMaxFileSize);
    onInput(newValues.map((vals) => {
      return {
        key: vals.key.toString(),
        value: vals.value,
      };
    }));
  }

  checkFileSize = (max) => {
    const { uploadedFilesSize } = this.state;
    const { errorMessage } = this.props;
    if (uploadedFilesSize >= max) {
      this.setState({
        errorMessage: `Max size of uploaded files should be less then ${max / 1024 / 1024}MB`,
      });
    } else {
      this.setState({
        errorMessage,
      });
    }
  }

  renderPreviewItem = (value, index, metadata) => {
    return (
      <div className={styles.previewItem} key={index}>
        <img src={RemoveFileSVG} alt="X" className={styles.removeFile} onClick={() => this.removeFile(index, metadata)} />
        { metadata.accept.startsWith('image/')
          ? (<img className={styles.filePreview} src={value.content} alt={value.value.name} />)
          : (<div className={styles.filePreview}>{value.value.name}</div>)}
      </div>
    );
  }

  renderSingleItem = (metadata, index) => {
    const { values } = this.state;
    return (
      <div key={index} className={styles.fileItem}>
        <div className={styles.previewContainer}>
          { values.map((value, key) => {
            return this.renderPreviewItem(value, key, metadata);
          })}
        </div>
        <input
            type="file"
            accept={metadata.accept}
            multiple={true}
            ref={this.fileInputRef}
            className={[styles.fileInput].join(' ')}
            onInput={(e) => this.saveValue(e.target.files, metadata)}
        />
        <button className={styles.uploadFileButton} onClick={this.uploadFile} type="button">
          { metadata.accept.startsWith('image/')
            ? (<img className={styles.addImageButtonIcon} src={AddImageSVG} alt="" />)
            : (<img className={styles.addImageButtonIcon} src={AddFileSVG} alt="" />)} {metadata.label}
        </button>
      </div>
    );
  }

  render() {
    const { item, className } = this.props;
    const { errorMessage } = this.state;
    return (
      <div className={[styles.formItem, className].join(' ')}>
        <div className={styles.formItemTitle}>{ item.title }</div>
        <div className={styles.formItemSubTitle}>{ item.subTitle }</div>
        { errorMessage ? (<div className={styles.errorMessage}><img src={ RedInfoSVG } alt="i" /> { errorMessage }</div>) : null }
        { item.metadata.map((metadata, k) => {
          return this.renderSingleItem(metadata, k);
        }) }
      </div>
    );
  }
}

FeedbackFormFile.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    isRequire: PropTypes.bool,
    priority: PropTypes.number,
    metadata: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
      markdown: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
      uploadMaxFileSize: PropTypes.number,
      accept: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null, undefined])]),
    })).isRequired,
  }).isRequired,
  onInput: PropTypes.func,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
};

FeedbackFormFile.defaultProps = {
    onInput: () => {},
    className: '',
    errorMessage: '',
};

export default FeedbackFormFile;
