/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {ME_FINISHED_ORDERS_QUERY} from '../../graphql/user';

// COMPONENTS
import Button from "../../components/Button/Button";
import Loader from '../../components/Loader/Loader'
import ActiveOrders from "../../components/ActiveOrders/ActiveOrders";
import OrderHistoryWidget from '../../components/OrderHistoryWidget/OrderHistoryWidget';

import styles from './OrderHistory.module.css';
import { PAGINATION } from '../../shared/constants/common';

const OrderHistory = () => {
  const [initialLoadingState, setInitialLoadingState] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showPerPage = 5;
  const showJustAFew = 3;

  const [fetch, { loading, data, error  }] = useLazyQuery(ME_FINISHED_ORDERS_QUERY, {
    variables:{
      orderBy: { column: 'created_at', order: 'DESC' },
    },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (!loading) {
      fetch({
        variables: {
          orderBy: {
            column: 'created_at',
            order: 'DESC'
          },
          pagination: {
            limit: 5,
            offset: (currentPage - 1) * 5
          }
        },
      });
    }
  }, [currentPage]);

  // Clear the initial loading state if we can display either data or errors
  const dataIsReady = data || error;
  if (initialLoadingState && dataIsReady) {
    setInitialLoadingState(false);
  }

  // Show the full-page loader for the initial loading state
  if (initialLoadingState) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  const renderPaginationLink = (
    activePage,
    nextPage,
    pageNumber,
    placeholder
  ) => {
    return (
      <button
        key={activePage}
        type="button"
        className={
          activePage === pageNumber ? styles.activePaginationButton : null
        }
        onClick={() => {
          if (activePage !== pageNumber) {
            const completedOrdersHeading = document.querySelector(
              "#completedOrders"
            );
            if (completedOrdersHeading) {
              window.scrollTo({
                top: completedOrdersHeading.offsetTop - 100,
                behavior: "smooth"
              });
            }

            setCurrentPage(nextPage);
          }
        }}
      >
        {placeholder}
      </button>
    );
  };

  const renderFinishedOrders = () => {
    // data is undefined while the pagination query is in flight
    const finishedOrdersData = data?.me.finishedOrders.orders || [];
    const totalCount = data?.me.finishedOrders.totalCount || 0;
    const totalPages = data?.me.finishedOrders.totalPages || 0;
    const isNextPage =
      currentPage > PAGINATION.CURRENT && totalPages > PAGINATION.TOTAL;
    const hasMorePages = totalPages > PAGINATION.TOTAL;
    return (
      <>
        <h1 id="completedOrders">Completed Orders</h1>

        {loading ? (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        ) : (
          <>
            {!finishedOrdersData.length && <p>No completed orders</p>}

            {finishedOrdersData
              .slice(0, showMore ? showPerPage : showJustAFew)
              .map(order => {
                if (order.items.length > 0) {
                  return <OrderHistoryWidget key={order.id} order={order} />;
                }

                return null;
              })}

            {/* Show more */}
            {!showMore && totalCount > showJustAFew && (
              <div className={styles.seeMoreContainer}>
                <Button
                  id="seeMoreButton"
                  handleSubmit={() => setShowMore(true)}
                  label="See more orders"
                  customContainerStyles={styles.seeMoreButton}
                />
              </div>
            )}

            {/* Pagination */}
            {showMore && totalPages > 1 && (
              <div
                id="paginationContainer"
                className={styles.paginationContainer}
              >
                {isNextPage &&
                  renderPaginationLink(
                    currentPage,
                    currentPage - 1,
                    PAGINATION.CURRENT,
                    "<"
                  )}
                {Array.from({ length: totalPages }, (_, i) => i + 1)
                  .slice(currentPage - 1, currentPage - 1 + PAGINATION.TOTAL)
                  .map(page =>
                    renderPaginationLink(currentPage, page, page, page)
                  )}
                {hasMorePages &&
                  renderPaginationLink(
                    currentPage,
                    currentPage + 1,
                    totalPages,
                    ">"
                  )}
              </div>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <div className={`${styles.pageWrapper} text-dark dark:text-white`}>
      <div className={`${styles.pageContainer} max-w-screen-desktop mx-auto pb-4`}>

        {error ? (
          // In case of error, show error message
          <div id="errorContainer" className={styles.errorContainer}>
            <p>Oops, something went wrong</p>
            <p>There was a network error 400, try refreshing your app</p>
            <span onClick={() => window.location.reload()}> {/* eslint-disable-line */}
              Reload
            </span>
          </div>
        ) : (
          // Everything is fine, show orders
          <>
            <ActiveOrders before={(<h1>Active Orders</h1>)} />
            {renderFinishedOrders()}
          </>
        )}
      </div>
    </div>
  );
};

export default OrderHistory;
