import { combineReducers } from 'redux';

// REDUCERS
import elements from './elementsReducer';
import user from './userReducer';
import order from './order';
import checkout from './checkout';
import payment from './payment';
import address from './address';
import doughPro from './doughProReducer';
import customCakeTopper from "./customCakeTopper";
import braze from './braze';
import shipping from './shippingReducer';
import product from './productReducer';
import currency from './currencyReducer';
import country from './countryReducer';
import footerSettings from './footerSettingsReducer';
import tips from './tipsReducer';
import ccDetails from './ccDetailsReducer';
import giftCard from './giftCard';
import orderTotalsTitles from './orderTotalsTitles';
import contactUsSetting from './contactUsSettingReducer';
import theme from './themeReducer';
import loyalty from './loyalty';
import siteSettings from './siteSettings';

export default combineReducers({
  elements,
  user,
  order,
  checkout,
  payment,
  address,
  doughPro,
  customCakeTopper,
  braze,
  shipping,
  country,
  currency,
  footerSettings,
  tips,
  ccDetails,
  siteSettings,
  giftCard,
  orderTotalsTitles,
  product,
  contactUsSetting,
  theme,
  loyalty
});
